import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BlogBodyType, BlogDetailType } from '../../utils/interface'
import { AspectRatio, Box, Divider, Link, Stack, Typography } from '@mui/joy'
import Scaffold from '../scaffold/Scaffold'
import axios from 'axios'
import { SERVERURL } from '../../utils/utils'

const getBlogBody = (blogBody: BlogBodyType) => {
  const type = blogBody.type
  switch (type) {
    case 'youtube':
      return (
        <div>
          <AspectRatio>
            <iframe
              src={blogBody.content}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </AspectRatio>
        </div>
      )
    case 'paragraph':
      return (
        <div>
          {blogBody.content.split(/\[([^\]]+)\]/g).map((part, idx) => {
            if (part.includes('https')) {
              return (
                <Link
                  key={idx}
                  target='_blank'
                  rel='noreferrer'
                  level='body-lg'
                  href={part.split(',')[1].trim()}
                  sx={{
                    display: 'inline'
                  }}
                >
                  {part.split(',')[0].trim()}
                </Link>
              )
            } else {
              return (
                <Typography
                  key={idx}
                  level='body-lg'
                  sx={{
                    display: 'inline'
                  }}
                >
                  {part}
                </Typography>
              )
            }
          })}
        </div>
      )
    case 'title':
      return (
        <Box
          sx={{
            mb: 1
          }}
        >
          <Typography level='h4' fontWeight={'bold'}>
            {blogBody.content.toLocaleUpperCase()}
          </Typography>
        </Box>
      )
    case 'image':
      return (
        <AspectRatio>
          <img src={blogBody.content}></img>
        </AspectRatio>
      )
    default:
      ;<div></div>
  }
}

const BlogDetail = () => {
  const [blog, setBlog] = useState<BlogDetailType>()
  const { blogId } = useParams()

  useEffect(() => {
    getBlog()
  }, [])

  const getBlog = async () => {
    const ret = (
      await axios.get(`${SERVERURL}/api/blog/${blogId}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420'
        }
      })
    ).data
    if (ret) {
      const newBlog: BlogDetailType = {
        id: ret['id'],
        header: ret['header'],
        subheader: ret['sub_header'],
        timestamp: ret['timestamp'],
        blogBodies: ret['blog_bodies'].map((body: any) => {
          return {
            index: body['index'],
            content: body['content'],
            type: body['type'],
            margin: body['margin']
          }
        })
      }
      setBlog(newBlog)
    }
  }

  if (!blog) {
    return <div></div>
  }

  const date = new Date(blog.timestamp)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  } as Intl.DateTimeFormatOptions
  const formattedDate = date.toLocaleString('en-US', options)

  return (
    <Scaffold
      mainBody={
        <div>
          <Box
            sx={{
              padding: {
                xs: '15% 30px 15px 30px',
                md: '5% 20% 30px 20%'
              }
            }}
          >
            <Stack spacing={2}>
              <Typography level='h2'>{blog.header}</Typography>
              <Typography level='title-lg'>{blog.subheader}</Typography>
              <Typography color='neutral' level='body-sm'>
                {formattedDate}
              </Typography>
            </Stack>
            <Box mt={5}>
              <Divider
                sx={{
                  width: '100%',
                  height: 2,
                  backgroundColor: 'neutral.plainColor',
                  mb: 1
                }}
              />
            </Box>
            <Box
              sx={{
                mt: 5
              }}
            >
              {blog.blogBodies.map((body, idx) => (
                <Box
                  key={`body_${idx}`}
                  mt={body.margin[0]}
                  mr={body.margin[1]}
                  mb={body.margin[2]}
                  ml={body.margin[3]}
                >
                  {getBlogBody(body)}
                </Box>
              ))}
              <Box
                sx={{
                  mt: 2
                }}
              >
                <Typography fontWeight='bold' level='title-md'>
                  <Link href='/'>Click here</Link> to learn about more how Auto
                  Trust Service can help you keep your car safe
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>
      }
    />
  )
}

export default BlogDetail
