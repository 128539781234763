import { AspectRatio, Box, Button, Grid, Typography } from '@mui/joy'
import heroImage from '../../assets/hero_header.svg'

const Hero = () => {
  return (
    <div>
      <Box
        sx={{
          padding: {
            md: '5% 10% 8% 10%',
            sm: '5% 10% 8% 10%',
            xs: '10% 10% 10% 10%'
          }
        }}
      >
        <Grid container>
          <Grid xs={12} lg={6}>
            <Typography
              level='h1'
              sx={{
                fontSize: {
                  sm: '3rem',
                  marginBottom: '24px'
                }
              }}
            >
              Book Trusted Auto Care Services
            </Typography>
            <Typography
              fontWeight='md'
              level='body-lg'
              sx={{
                fontSize: {
                  sm: '1.2rem'
                },
                width: {
                  md: '60%'
                }
              }}
            >
              The best place to quickly find and book trusted service shops for your
              vehicle
            </Typography>
          </Grid>
          <Grid
            md={6}
            sx={{
              display: {
                xs: 'none',
                lg: 'block'
              }
            }}
          >
            <AspectRatio objectFit='cover'>
              <img src={heroImage} />
            </AspectRatio>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Hero
