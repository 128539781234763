import React, { useEffect, useState } from 'react'
import Scaffold from '../scaffold/Scaffold'
import { Box, Card, CardContent, Grid, Link, Stack, Typography } from '@mui/joy'
import { BlogSnippet } from '../../utils/interface'
import { SERVERURL } from '../../utils/utils'
import axios from 'axios'

const Blogs = () => {
  const [blogsSnippets, setBlogSnippets] = useState<BlogSnippet[]>([])

  useEffect(() => {
    getBlogSnippets()
  }, [])

  const getBlogSnippets = async () => {
    const ret = (
      await axios.get(`${SERVERURL}/api/blogsnippets`, {
        headers: {
          'ngrok-skip-browser-warning': '69420'
        }
      })
    ).data['snippets']
    if (ret) {
      const snippets = ret.map((snippet: any) => {
        return {
          blogId: snippet['blogId'],
          header: snippet['header'],
          snippet: snippet['snippet'],
          timestamp: snippet['timestamp']
        }
      })
      setBlogSnippets(snippets)
    }
  }

  return (
    <div>
      <Scaffold
        mainBody={
          <div>
            <Box
              sx={{
                mt: 5,
                margin: {
                  xs: '15% 15px 15px 15px',
                  md: '5% 20% 30px 20%'
                }
              }}
            >
              <Box>
                <Stack spacing={2}>
                 
                  <Typography level='h1'>ATS Blog</Typography>
                  <Typography level='body-lg'>
                    Explore insightful blog series, where we regularly share
                    valuable tips and step-by-step guides on maintaining and
                    enhancing your car's performance. Learn more about{' '}
                    <Link href={'/'}>Auto Trust Service </Link>
                  </Typography>
                </Stack>
              </Box>
              {blogsSnippets.map(blogSnippet => {
                const date = new Date(blogSnippet.timestamp)
                const options = {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                } as Intl.DateTimeFormatOptions
                const formattedDate = date.toLocaleString('en-US', options)
                return (
                  <Box
                    sx={{
                      mt: 5,
                      '&:hover': {
                        boxShadow: 'md',
                        borderColor: 'neutral.outlinedHoverBorder'
                      }
                    }}
                  >
                    <Card
                      variant='outlined'
                      orientation='horizontal'
                      sx={{
                        backgroundColor: 'common.black'
                      }}
                    >
                      <CardContent>
                        <Stack spacing={2}>
                          <Typography level='h3'>
                            {blogSnippet.header}
                          </Typography>
                          <Typography color='neutral' level='body-sm'>
                            {formattedDate}
                          </Typography>
                          <Typography level='body-md'>
                            {blogSnippet.snippet}
                          </Typography>
                          <Link
                            href={`/blog/${blogSnippet.blogId}`}
                            level='body-sm'
                          >
                            Read More
                          </Link>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Box>
                )
              })}
            </Box>
          </div>
        }
      />
    </div>
  )
}

export default Blogs
