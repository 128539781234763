import { AxiosRequestConfig, CreateAxiosDefaults } from "axios"

export interface ScaffoldProps {
  mainBody: JSX.Element
}

export interface ButtonProps {
  title: string
  size: 'sm' | 'md' | 'lg'
}

export interface PlaceDetailProps {
  autoCarePlace: AutoCarePlace | null
}

export interface AppRequest {
    configuraton: CreateAxiosDefaults
    endpoint: string
}

export interface SearchResponse {
    currentPage: number
    found: number
    length: number
    places: AutoCarePlace[]
}

export interface Review {
  reviewPhotos: string[]
  reviewRecency: string
  reviewText: string
  reviewerName: string
  reviewerPhoto: string
  sentimentRating: number
}

export interface AutoCarePlace {
  id: string
  name: string
  careType: string
  otherDetails: string[]
  reviewsAmount: number
  stars: number
  trustScore: number
  reviews: Review[]
}

export interface BlogDetailType {
  id: string,
  header: string
  subheader: string,
  timestamp: number,
  blogBodies: BlogBodyType[],
}

export interface BlogBodyType {
  index: number,
  content: string,
  type: string,
  margin: number[] //top, right bottom, left
}

export interface BlogSnippet {
  blogId: string, 
  header: string,
  snippet: string,
  timestamp: number,
}

export enum ServiceType {
    AutoRepair= 'Auto Repair',
    TireServices='Tire Services',
    CarWash= 'Car Wash and Detailing',
    None=''
}
