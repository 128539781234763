import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Scaffold from '../scaffold/Scaffold'
import {
  AspectRatio,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography
} from '@mui/joy'
import { AutoCarePlace, PlaceDetailProps } from '../../utils/interface'
import axios from 'axios'
import { SERVERURL, getRatingColor, pullAddress } from '../../utils/utils'

import mixpanel from 'mixpanel-browser'

function PlaceDetail (props: PlaceDetailProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { placeId } = useParams()
  const [place, setPlace] = useState<AutoCarePlace>()

  useEffect(() => {
    if (props.autoCarePlace) {
      setPlace({
        ...props.autoCarePlace
      })
    } else {
      if (placeId) {
        getPlace(placeId)
      }
    }
  }, [])

  const getPlace = async (id: string) => {
    const place = (
      await axios.get(`${SERVERURL}/api/auto_care/${id}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420'
        }
      })
    ).data
    if (place) {
      const autoCarePlace: AutoCarePlace = {
        id: place['id'],
        name: place['name'],
        careType: place['care_type'],
        otherDetails: place['other_details'],
        reviewsAmount: place['reviews_amount'],
        stars: place['stars'],
        trustScore: place['trust_score'],
        reviews: place['reviews'].map((review: any) => {
          return {
            reviewPhotos: review['review_photos'],
            reviewRecency: review['review_recency'],
            reviewText: review['review_text'],
            reviewerName: review['reviewer_name'],
            reviewerPhoto: review['reviewer_photo'],
            sentimentRating: review['sentiment_rating']
          }
        })
      }
      setPlace(autoCarePlace)
    }
  }

  const getRatingToEmoji = (rating: number) => {
    if (rating > 75) {
      return (
        <Box
          sx={{
            mr: 1
          }}
        >
          <span>😁</span>
        </Box>
      )
    }
    if (rating <= 60) {
      return (
        <Box
          sx={{
            mr: 1
          }}
        >
          <span>😡</span>
        </Box>
      )
    } else {
      return (
        <Box
          sx={{
            mr: 1
          }}
        >
          <span>😐</span>
        </Box>
      )
    }
  }

  if (!place) {
    return (
      <Scaffold
        mainBody={
          <div>
            <Box
              sx={{
                marginLeft: '40%'
              }}
            >
              <CircularProgress variant={'outlined'} color='success' />
            </Box>
          </div>
        }
      />
    )
  }

  const trustPercentage = Math.round((place.trustScore / 5) * 100)

  return (
    <div>
      <Scaffold
        mainBody={
          <div>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
              <ModalDialog
                aria-labelledby='nested-modal-title'
                aria-describedby='nested-modal-description'
                sx={theme => ({
                  [theme.breakpoints.only('xs')]: {
                    top: 'unset',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderRadius: 0,
                    transform: 'none',
                    maxWidth: 'unset'
                  }
                })}
              >
                <ModalClose variant='plain' sx={{ m: 1 }} />
                <Typography id='nested-modal-title' level='h2'>
                  Schedule Appointment
                </Typography>
                <Typography
                  id='nested-modal-description'
                  textColor='text.tertiary'
                >
                  Book an appointment at <span>{place.name} </span>by calling
                  the number below
                </Typography>
                <a
                  href={`tel:${
                    place.otherDetails[place.otherDetails.length - 1]
                  }`}
                >
                  {place.otherDetails[place.otherDetails.length - 1]}
                </a>
              </ModalDialog>
            </Modal>
            <Box
              sx={{
                ml: 2,
                mr: 1,
                mt: 3,
                padding: {
                  sm: '0px 10% 0px 10%',
                  xs: '0 0 0 0'
                }
              }}
            >
              <Box
                sx={{
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                }}
              >
                <Box
                  sx={{
                    mr: 2
                  }}
                >
                  <Stack spacing={1}>
                    <CircularProgress
                      size='lg'
                      determinate
                      value={trustPercentage}
                      color={getRatingColor(trustPercentage)}
                    >
                      <Typography>{trustPercentage}%</Typography>
                    </CircularProgress>
                    <Typography level='body-xs'>Trust Score</Typography>
                  </Stack>
                </Box>
                <Stack>
                  <Typography level='h3' fontWeight={'bold'}>
                    {place.name}
                  </Typography>
                  <Typography
                    level='body-sm'
                    aria-describedby='card-address'
                    mb={1}
                    color='neutral'
                  >
                    {place.otherDetails.length == 3
                      ? pullAddress(place.otherDetails[1])
                      : ''}
                  </Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  mb: 2
                }}
              >
                <Button
                  size='md'
                  variant={'solid'}
                  color='primary'
                  onClick={() => {
                    mixpanel.track('book-attempt', {
                      place_id: place.id
                    })
                    setModalOpen(true)
                  }}
                >
                  Schedule
                </Button>
              </Box>
              <Box>
                <Divider
                  sx={{
                    width: '100%',
                    height: 5,
                    backgroundColor: 'neutral.plainColor',
                    mb: 1
                  }}
                />
                <List>
                  {place.reviews.map((review, idx) => (
                    <ListItem key={idx}>
                      <Box
                        sx={{
                          mb: 3
                        }}
                      >
                        <Stack>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center'
                            }}
                          >
                            {getRatingToEmoji(
                              Math.round((review.sentimentRating / 5) * 100)
                            )}
                            <Typography level='title-lg' fontWeight={'bold'}>
                              {review.reviewerName}
                            </Typography>
                          </Box>
                          {/* <Typography level='body-xs' color='neutral'>
                            {review.reviewRecency}
                          </Typography> */}
                          <Box
                            sx={{
                              height: 5
                            }}
                          ></Box>
                          <Typography level='body-sm'>
                            {review.reviewText}
                          </Typography>
                          <Box
                            sx={{
                              mt: 2
                            }}
                          ></Box>
                          <Grid container>
                            {review.reviewPhotos.map((photo, idx) => (
                              <Box
                                sx={{
                                  mb: 1,
                                  mr: 2
                                }}
                              >
                                <div
                                  onClick={() => {
                                    document
                                      .getElementById(`${idx}`)
                                      ?.requestFullscreen()
                                  }}
                                >
                                  <Grid xs={12} sm={6} md={3}>
                                    <img id={`${idx}`} src={photo} alt='' />
                                  </Grid>
                                </div>
                              </Box>
                            ))}
                          </Grid>
                        </Stack>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </div>
        }
      />
    </div>
  )
}

export default PlaceDetail
