import { Box, Button, ButtonProps, Typography } from '@mui/joy'
import React from 'react'

const PrimaryButton = (props: ButtonProps) => {
    return (
        <Box>
            <Button {...props} sx={{
                backgroundColor: "neutral.plainColor",
                ":hover": {
                    backgroundColor: "neutral.700",
                }
            }}>
                <Typography fontSize={props.size}>{props.title}</Typography>
            </Button>
        </Box>
    )
}

PrimaryButton.defaultProps = {
    title: '',
    size: 'sm'
}

export default PrimaryButton
