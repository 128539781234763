import React from 'react'
import {
  AspectRatio,
  Box,
  Card,
  CardContent,
  Link,
  Stack,
  Typography
} from '@mui/joy'
import { AutoCarePlace } from '../../utils/interface'
import CircularProgress from '@mui/joy/CircularProgress'
import { getRatingColor, pullAddress } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'

function PlaceCard (props: { place: AutoCarePlace }) {
  const place: AutoCarePlace = props.place
  const trustPercentage = Math.round((place.trustScore / 5) * 100)
  const navigate = useNavigate()

  const firstReview = place.reviews.length == 0 ? null : place.reviews[0]
  return (
    <div
      onClick={() => {
        navigate(`/place/${place.id}`)
      }}
    >
      <Card
        variant='outlined'
        orientation='horizontal'
        sx={{
          '&:hover': {
            boxShadow: 'md',
            borderColor: 'neutral.outlinedHoverBorder'
          }
        }}
      >
        <Box sx={{ width: 90 }}>
          <Stack spacing={2}>
            <CircularProgress
              size='lg'
              determinate
              value={trustPercentage}
              color={getRatingColor(trustPercentage)}
            >
              <Typography>{trustPercentage}%</Typography>
            </CircularProgress>
            <Typography level='body-sm'>Trust Score</Typography>
          </Stack>
        </Box>
        <CardContent>
          <Link level='title-lg' id='card-description' href={`/place/${place.id}`}>
            {place.name}
          </Link>
          <Typography
            level='body-sm'
            aria-describedby='card-address'
            mb={1}
            color='neutral'
          >
            {place.otherDetails.length == 3
              ? pullAddress(place.otherDetails[1])
              : ''}
          </Typography>
          <Stack>
            <Typography level='body-sm' aria-describedby='init review' mb={1}>
              {firstReview === null
                ? ''
                : firstReview.reviewText.length > 200
                ? `${firstReview.reviewText.substring(0, 199)}...`
                : firstReview.reviewText}
            </Typography>
            <Link level='body-sm'  href={`/place/${place.id}`}>Read More</Link>
          </Stack>
        </CardContent>
      </Card>
    </div>
  )
}

export default PlaceCard
