// export const SERVERURL = 'http://127.0.0.1:8000'
export const SERVERURL = 'https://ce77-216-80-94-58.ngrok-free.app'

export const getRatingColor = (rating: number) => {
    if (rating > 75) {
      return 'success'
    }
    if (rating <= 60) {
      return 'danger'
    } else {
      return 'warning'
    }
  }

  export const pullAddress = (text: string) => {
    let addressText = text
    if (text.includes('Open')) {
      addressText = addressText.split('Open')[0]
    }
    if (text.includes('Closed')) {
      addressText = addressText.split('Closed')[0]
    }
    if (text.includes('Closes')) {
      addressText = addressText.split('Closes')[0]
    }
    return addressText
  }