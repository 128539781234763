import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography
} from '@mui/joy'
import { NotificationsNoneRounded, MenuRounded } from '@mui/icons-material'
import { useState } from 'react'
import PrimaryButton from '../primary_button/PrimaryButton'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.png'

const profileURL =
  'https://www.iit.edu/sites/default/files/styles/width_1280/public/2021-05/prince_hodonou_1280x850.jpg?itok=IyyiZKq3'

const Header = () => {
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <div>
      <Box
        sx={{
          display: {
            sm: 'none'
          }
        }}
      >
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <List>
            <ListItem>
              <PrimaryButton
                title='Blog'
                onClick={() => {
                  setDrawerOpen(false)
                  navigate('/blogs')
                }}
              />
            </ListItem>
          </List>
        </Drawer>
        <Box
          sx={{
            backgroundColor: 'neutral.plainColor',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '15px 15px 15px 8px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                marginRight: '8px'
              }}
            >
              <IconButton
                variant='outlined'
                onClick={() => setDrawerOpen(true)}
              >
                <MenuRounded />
              </IconButton>
            </Box>
            <Link to='/' style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <AspectRatio
                    sx={{
                      width: '40px',
                      marginRight: '8px'
                    }}
                  >
                    <img src={logo} />
                  </AspectRatio>
                </Box>
                <Box>
                  <Typography level='h3'>Auto Trust Service</Typography>
                </Box>
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'block'
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: 'neutral.plainColor',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 10% 20px 10%'
          }}
        >
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box>
                <AspectRatio
                  sx={{
                    width: '60px'
                  }}
                >
                  <img src={logo} />
                </AspectRatio>
              </Box>
              <Box
                sx={{
                  marginRight: '60px'
                }}
              >
                <Typography level='h3'>Auto Trust Service</Typography>
              </Box>
            </Box>
          </Link>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <PrimaryButton
            size='lg'
              title='Blog'
              onClick={() => {
                navigate('/blogs')
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Header
