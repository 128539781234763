import React, { useEffect, useRef, useState } from 'react'
import Scaffold from '../../components/scaffold/Scaffold'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography
} from '@mui/joy'
import PlaceCard from '../../components/place/PlaceCard'
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  LocationCityRounded,
  SearchRounded
} from '@mui/icons-material'
import Done from '@mui/icons-material/Done'
import axios, { AxiosResponse } from 'axios'
import { useDebounce } from 'usehooks-ts'

import {
  AutoCarePlace,
  ServiceType,
  SearchResponse,
  AppRequest
} from '../../utils/interface'
import Hero from '../../components/hero/hero'
import PrimaryButton from '../../components/primary_button/PrimaryButton'
import { SERVERURL } from '../../utils/utils'

const searchToSearchResponse = (res: AxiosResponse) => {
  const data: [] = res.data.places
  const places = data.map(place => {
    const reviews: [] = place['reviews']
    const autoCarePlace: AutoCarePlace = {
      id: place['id'],
      name: place['name'],
      careType: place['care_type'],
      otherDetails: place['other_details'],
      reviewsAmount: place['reviews_amount'],
      stars: place['stars'],
      trustScore: place['trust_score'],
      reviews: reviews.map(review => {
        return {
          reviewPhotos: review['review_photos'],
          reviewRecency: review['review_recency'],
          reviewText: review['review_text'],
          reviewerName: review['reviewer_name'],
          reviewerPhoto: review['reviewer_photo'],
          sentimentRating: review['sentiment_rating']
        }
      })
    }
    return autoCarePlace
  })
  const searchResponse: SearchResponse = {
    currentPage: res.data['current_page'],
    found: res.data['found'],
    length: res.data['length'],
    places: places
  }
  return searchResponse
}

function Browse () {
  const nextRequest = useRef<AppRequest>({
    configuraton: {},
    endpoint: ''
  })
  const nextPaginatedPage = useRef(1)
  const totalPages = useRef<number>()
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState<ServiceType>(ServiceType.AutoRepair)
  const [places, setPlaces] = useState<AutoCarePlace[]>([])
  const [modalOpen, setModalOpen] = useState(false)

  const [searchName, setSearchName] = useState<string>('*')
  const debouncedValue = useDebounce<string>(searchName, 800)

  const [userEmail, setUserEmail] = useState('')
  const [userZip, setUserZip] = useState('')

  useEffect(() => {
    getPlaces()
  }, [value])

  useEffect(() => {
    if (searchName !== '*') {
      if (!searchName) {
        setValue(ServiceType.AutoRepair)
      } else {
        setValue(ServiceType.None)
        searchPlaceByName(searchName)
      }
    }
  }, [debouncedValue])

  const makeRequest = async (req: AppRequest) => {
    setIsLoading(true)
    req['configuraton']['headers'] = {
      'ngrok-skip-browser-warning': '69420'
    }
    const res = await axios.create(req.configuraton).get(req.endpoint)
    const searchResponse = searchToSearchResponse(res)
    setIsLoading(false)
    setPlaces(searchResponse.places)

    nextRequest.current = req

    nextPaginatedPage.current += 1

    nextRequest.current!.configuraton.params.page = nextPaginatedPage.current
    if (searchResponse.found !== 0) {
      totalPages.current = Math.round(
        searchResponse.found / searchResponse.length
      )
    } else {
      totalPages.current = 1
    }
    return searchResponse
  }

  const submitUserInfo = () => {
    axios.post(`${SERVERURL}/submituserinfo`, {
      user_email: userEmail,
      user_zip: userZip
    })
  }

  const getPlaces = async () => {
    const valueQueryMapping = {
      'Auto Repair': 'auto repair mechanic shop',
      'Tire Services': 'tire wheel services',
      'Car Wash and Detailing': 'car wash and detailing clean'
    }
    if (value != ServiceType.None) {
      nextPaginatedPage.current = 1
      makeRequest({
        configuraton: {
          baseURL: SERVERURL,
          params: {
            zip_code: '60560',
            service: valueQueryMapping[value],
            page: nextPaginatedPage.current
          }
        },
        endpoint: '/api/auto_care'
      })
    }
  }

  const searchPlaceByName = async (query: string) => {
    nextPaginatedPage.current = 1
    makeRequest({
      configuraton: {
        baseURL: SERVERURL,
        params: {
          name: query,
          page: nextPaginatedPage.current
        }
      },
      endpoint: '/api/auto_care/search'
    })
  }

  const onNextPage = () => {
    makeRequest(nextRequest.current)
  }

  const onPrevPage = () => {
    nextPaginatedPage.current -= 2
    nextRequest.current.configuraton.params.page = nextPaginatedPage.current
    makeRequest(nextRequest.current)
  }

  return (
    <Scaffold
      mainBody={
        <div>
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <ModalDialog
              aria-labelledby='nested-modal-title'
              aria-describedby='nested-modal-description'
              sx={theme => ({
                [theme.breakpoints.only('xs')]: {
                  top: 'unset',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  borderRadius: 0,
                  transform: 'none',
                  maxWidth: 'unset'
                }
              })}
            >
              <ModalClose variant='plain' sx={{ m: 1 }} />
              <Typography id='nested-modal-title' level='h2'>
                Coming Soon
              </Typography>
              <Typography
                id='nested-modal-description'
                textColor='text.tertiary'
              >
                We are working on analying shops for other zipcodes. Enter your
                zipcode and your email and we'll let you know when we get shops
                for you.
              </Typography>
              <form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault()
                  if (userEmail && userZip) {
                    setModalOpen(false)
                    alert("We'll be in touch soon")
                    submitUserInfo()
                  }
                }}
              >
                <Stack spacing={2}>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      onChange={e => setUserEmail(e.target.value)}
                      type='email'
                      autoFocus
                      required
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Zipcode</FormLabel>
                    <Input
                      onChange={e => setUserZip(e.target.value)}
                      type='text'
                      required
                    />
                  </FormControl>
                  <PrimaryButton type='submit' title='Submit' />
                </Stack>
              </form>
            </ModalDialog>
          </Modal>
          <Box
            sx={{
              padding: {
                xs: '0px 15px 0 15px',
                sm: '0 10% 0 10%'
              }
            }}
          >
            <Hero />
            <Box
              sx={{
                margin: '0px 0 5px 0'
              }}
            >
              <Input
                startDecorator={<SearchRounded />}
                placeholder='Search auto care place'
                sx={{
                  width: {
                    md: '50%'
                  },
                  height: {
                    md: '50px'
                  }
                }}
                onChange={event => setSearchName(event.target.value)}
              />
            </Box>
            <div onClick={event => setModalOpen(true)}>
              <Box
                sx={{
                  margin: '0px 0 15px 0'
                }}
              >
                <Input
                  startDecorator={<LocationCityRounded />}
                  value={'60604'}
                  disabled
                  sx={{
                    width: {
                      md: '20%'
                    }
                  }}
                  onChange={event => setSearchName(event.target.value)}
                />
              </Box>
            </div>
            <Box
              sx={{
                marginBottom: '45px'
              }}
            >
              <div role='group' aria-labelledby='rank'>
                <List
                  orientation='horizontal'
                  wrap
                  sx={{
                    '--List-gap': '8px',
                    '--ListItem-radius': '20px',
                    '--ListItem-minHeight': '32px',
                    '--ListItem-gap': '4px'
                  }}
                >
                  {[
                    ServiceType.AutoRepair,
                    ServiceType.TireServices,
                    ServiceType.CarWash
                  ].map((item, index) => (
                    <ListItem key={item}>
                      {value.includes(item) && (
                        <Done
                          fontSize='medium'
                          color='primary'
                          sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                        />
                      )}
                      <Checkbox
                        size='sm'
                        disableIcon
                        overlay
                        label={item}
                        checked={value.includes(item)}
                        variant={value.includes(item) ? 'soft' : 'outlined'}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setValue(item)
                        }}
                        slotProps={{
                          action: ({ checked }) => ({
                            sx: checked
                              ? {
                                  border: '1px solid',
                                  borderColor: 'primary.500'
                                }
                              : {}
                          })
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Box>
            {isLoading ? (
              <Box
                sx={{
                  marginLeft: '40%'
                }}
              >
                <CircularProgress variant={'outlined'} color='success' />
              </Box>
            ) : (
              <div></div>
            )}
            <Grid container spacing={2}>
              {places.map((place, idx) => (
                <Grid xs={12} key={idx}>
                  <PlaceCard place={place} />
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                mt: 2
              }}
            >
              <Box
                sx={{
                  marginRight: '10px'
                }}
              >
                <PrimaryButton
                  title='Back'
                  startDecorator={<ArrowBackIosRounded />}
                  onClick={e => onPrevPage()}
                  disabled={nextPaginatedPage.current === 2}
                />
              </Box>
              <PrimaryButton
                title='Next'
                endDecorator={<ArrowForwardIosRounded />}
                onClick={e => onNextPage()}
                disabled={nextPaginatedPage.current - 1 === totalPages.current}
              />
            </Box>
          </Box>
        </div>
      }
    />
  )
}

export default Browse
