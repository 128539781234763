import { Box } from '@mui/joy'
import Header from '../header/header'
import Footer from '../footer/footer'
import { ScaffoldProps } from '../../utils/interface'

const Scaffold = (props: ScaffoldProps) => {
  return (
    <div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr 15fr 1fr',
          height: '100vh',
        }}
      >
        <Header />
        {props.mainBody}
        <Box sx={{
          marginBottom: "15px"
        }}></Box>
        <Footer />
        <Box sx={{
          marginBottom: "15px"
        }}></Box>
      </Box>
    </div>
  )
}

export default Scaffold
