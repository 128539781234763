import { CssBaseline, CssVarsProvider, extendTheme } from '@mui/joy'
import Browse from './pages/browse/browse'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PlaceDetail from './components/place_detail/PlaceDetail'

import mixpanel from 'mixpanel-browser';
import BlogDetail from './components/blog/BlogDetail';
import Blogs from './components/blog/Blogs';
import DropInBlog from './components/blog/DropInBlog';
// mixpanel.init('4ab2d7adf200b299c91a6ea6e4244a9f', {debug: false, track_pageview: true, persistence: 'localStorage'});


const App = () => (
  <CssVarsProvider
    theme={extendTheme({
      colorSchemes: {
        dark: {
          palette: {
            common: {
              black: '#1a1a1a'
            },
            neutral: {
              plainColor: '#282828'
            },
            text: {
              primary: 'white',
              secondary: 'white',
              tertiary: 'white'
            }
          }
        }
      }
    })}
    defaultMode='dark'
  >
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Browse />} />
        <Route path='/dropinblog' element={<DropInBlog />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog/:blogId' element={<BlogDetail />}/>
        <Route path='/chicago' element={<Browse />} />
        <Route path='/place/:placeId' element={<PlaceDetail autoCarePlace={null}/>} />
      </Routes>
    </BrowserRouter>
  </CssVarsProvider>
)
export default App
