import { Box, Typography } from "@mui/joy"

const Footer = () => {
  return (
    <div>
      <Box sx={{
        padding: {
          'xs': '30px 8px 15px 8px',
          'sm': '30px 10% 15px 10%'
        }
      }}>
        <Typography fontSize='sm'>
          Copyright © 2023 Auto Trust Service
        </Typography>
      </Box>
    </div>
  )
}

export default Footer